export const getAnchorSku = (data = {}, itemId) => {
  return data?.product?.info?.gccExperienceOmsId || itemId;
};

export const flipPip = (
  configuredProductLabel,
  productData,
  hasPipFlip,
  productId,
  setHasPipFlip,
  itemId,
  anchorSku,
  setItemId
) => {
  const product = {
    configuredProductLabel,
  };
  const stockProduct = {
    configuredProductLabel: productData?.product?.identifiers?.productLabel,
  };
  if (hasPipFlip) {
    LIFE_CYCLE_EVENT_BUS.trigger(
      'configurator.configurator_choice_selected_event',
      { product: stockProduct }
    );
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_sticky_nav_event', {
      product: stockProduct,
    });
    LIFE_CYCLE_EVENT_BUS.trigger(
      'configurator.configurator_media_gallery_event',
      { extMedia: {} }
    );
    LIFE_CYCLE_EVENT_BUS.trigger('configurator.stock_product_chosen_event', {
      configuredItemId: productId,
    });
  } else if (!hasPipFlip) {
    LIFE_CYCLE_EVENT_BUS.trigger(
      'configurator.configurator_choice_selected_event',
      product
    );
    LIFE_CYCLE_EVENT_BUS.trigger(
      'configurator.configurator_sticky_nav_event',
      product
    );
  }
  setHasPipFlip(!hasPipFlip);
  return itemId === anchorSku ? setItemId(productId) : setItemId(anchorSku);

};

export const shouldDisplayConfigurator = (data = {}, isServer) => {
  return (
    !isServer &&
    data?.product?.identifiers?.productType === 'CONFIGURABLE_BLINDS'
  );
};

export const validatePipFlip = (data = {}, flag) => {
  return !flag && data?.product?.info?.gccExperienceOmsId;
};