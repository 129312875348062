import React from 'react';
import PropTypes from 'prop-types';
import { Clock } from '@one-thd/sui-icons';
import classNames from 'classnames';

const TimerInline = ({
  timeStringAndMessaging,
  shouldUseMessagingString,
  shouldIncludeSvg,
  invertText,
}) => {

  const style = {
    message: classNames(
      'sui-uppercase',
      { 'sui-text-primary': !invertText },
      { 'sui-text-inverse': invertText }
    ),
    timer: classNames(
      'sui-flex',
      'sui-mr-3',
      'sui-items-center',
      { 'sui-text-primary': !invertText },
      { 'sui-text-inverse': invertText }
    ),
    countdown: classNames(
      'sui-uppercase',
      { 'sui-text-primary': !invertText },
      { 'sui-text-inactive': invertText }
    )
  };

  const { messagingString, timeString } = timeStringAndMessaging;
  const { days, hours, minutes, seconds } = timeString;
  const countdown = { days,
    hrs: hours,
    min: minutes,
    sec: seconds };

  return (
    <div data-component="TimerEvent">
      <div className="sui-h1-display sui-flex sui-justify-center sui-items-center">
        {shouldIncludeSvg && (
          <span className={style.timer}>
            <Clock size="large" />
          </span>
        )}
        <span className={style.message}>
          {(messagingString && shouldUseMessagingString) && (
            messagingString
          )}
        </span>
      </div>

      <div className="sui-h2-display sui-flex sui-justify-around sm:sui-justify-center sui-pt-5 sm:sui-pt-2">
        {Object.entries(countdown).map(([key, value]) => {
          return (
            <span className={style.countdown} key={key}>
              <span className="sui-pr-1 sm:sui-pr-2">{value}</span>
              <span className={key !== 'sec' && 'sui-pr-3 sm:sui-pr-7'}>{key}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
};

TimerInline.propTypes = {
  /**
   * A boolean indicating whether or not you want to lead the timer with "Ends in" or "Ends on"
   */
  shouldUseMessagingString: PropTypes.bool,
  /**
   * To show clock image
   */
  shouldIncludeSvg: PropTypes.bool,
  /**
   * To show orage colour text
   */
  invertText: PropTypes.bool,
  /**
   * To show message and time
   */
  timeStringAndMessaging: PropTypes.oneOfType([
    PropTypes.shape({
      messagingString: PropTypes.string,
      timeString: PropTypes.string
    }),
    PropTypes.shape({
      messagingString: PropTypes.string,
      timeString: PropTypes.element
    })
  ])
};

TimerInline.defaultProps = {
  shouldUseMessagingString: false,
  shouldIncludeSvg: false,
  invertText: false,
  timeStringAndMessaging: {
    messagingString: '',
    timeString: ''
  }
};

export default TimerInline;
