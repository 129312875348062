import React, { useContext } from 'react';
import { SuperSku } from '@thd-olt-component-react/super-sku';
import { ExperienceContext } from '@thd-nucleus/experience-context';


const PipSuperSku = (props) => {
  const { productId, productData, displayConfigurator } = props || {};
  const isPaint = productData?.product?.info?.dotComColorEligible;
  const { channel } = useContext(ExperienceContext);


  if (!productData || displayConfigurator) {
    return null;
  }

  return (
    <SuperSku
      itemId={productId}
      large={isPaint}
      centeredCards={isPaint}
      disableMediaAndAvailability={isPaint}
      containerSizeHref="wall-area-calculator"
      hideCards={ channel === 'mobile'? !isPaint : false }
    />
  );
};

export { PipSuperSku };
