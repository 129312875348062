import omsId from './utils/getOmsId.js';
import { ContextBuilder } from '@thd-nucleus/experience-context';
import isDraftProduct from './utils/isDraftProduct.js';

const getConfiguratorSettings = (itemId = Number(omsId)) => {
  if (typeof window === 'undefined') {
    return;
  }

  const { experienceContext } = ContextBuilder.client();
  const { hosts } = experienceContext;
  const { configApiUrl, customizableProductsBaseUrl, globalCustomConfiguratorsBaseUrl, thdApiBaseUrl } = hosts;

  let base = window.location.hostname;

  const partnerProductId = isDraftProduct ? Number(omsId) : Number(itemId);
  const siteId = 11;

  return ({
      omnitureInfo: {
        primaryCategory: "appliances",
        subCategory1: "appliances>refrigerators",
        subCategory2: "appliances>refrigerators>french door refrigerators"
      },
      homeDepotAddToCartUrlBase: `https://${base}`,
      siteId: siteId,
      productId: 577701,
      partnerProductId: partnerProductId,
      configuratorMode: "create",
      logEnsightenEventsToConsole: true,
      brand: "Samsung",
      productName: "Bespoke Refrigerator",
      eleanorBaseUrl: globalCustomConfiguratorsBaseUrl,
      customBaseUrl: customizableProductsBaseUrl,
      thdCartApiUrl: `https://${base}/mcc-cart/v2/Cart`,
      thdSearchUrl: `https://${base}/s/`,
      externalCartUrl: `https://${base}/cart`,
      skuConfigApiUrl: `${configApiUrl}/api/skuconfiguration`,
      skuMappingApiBaseUrl: `${customizableProductsBaseUrl}/skumapping-api`,
      skuPricingApiUrl: `${customizableProductsBaseUrl}/skupricing-api`,
      thdBaseUrl: `https://${base}/`,
      thdWebBaseUrl: `https://${base}`,
      thdApiBaseUrl: thdApiBaseUrl,
      thdApiKey: "wbBP2jwbvRZ9QzMXpe4bTo5uFGcpEmpt",
      thdApiEnvironmentMap: "",
      graphThdApiBaseUrl: "https://apionline.homedepot.com",
      theHomeDepotComputedUrl: `${base}`,
      isCanonicalUrlSelfReferenced: true,
      isDraftProduct,
      useMSRP: false,
      useAutobahnPricing: false,
      excludedProductsFromOos: "558349,577415,577442,577443,577444,577445,577446,577451,571222,577701",
      useExperimentalCWVFeatures: false,
      pipfiguratorQuickLookupUrl: `${globalCustomConfiguratorsBaseUrl}/api/pipfiguratorQuickLookup/${partnerProductId}/site/${siteId}`,
      dynamicGalleryApiUrl: `${globalCustomConfiguratorsBaseUrl}/api/dynamicGallery`,
      layeredVisualizationImageUrl: `${globalCustomConfiguratorsBaseUrl}/api/visualization/GetLayeredImage`,
      useCatalogGraphql: true,
      //decking configs
      configuratorMode: "create",
      defaultLeadTime: 5,
      createConfigurationApi: `${customizableProductsBaseUrl}/custom-decks-api/api/configuration`,
      getOptionsApi: `${globalCustomConfiguratorsBaseUrl}/catalog/productconfiguration/getconfiguratoroptions`,
      isDevEnvironment: false,
      decksApiUrl: `${customizableProductsBaseUrl}/custom-decks-api/api`,
      skuMappingApiUrl: customizableProductsBaseUrl,
      scene7BaseUrl: `${customizableProductsBaseUrl}/is/image/blinds`,
      pipfiguratorQuickLookupUrl: `${globalCustomConfiguratorsBaseUrl}/api/pipfiguratorQuickLookup/${partnerProductId}/site/${siteId}`,
      configApiUrl: `${configApiUrl}/api/Configuration/`,
      omsApiUrl: `${configApiUrl}/api/GetProductOmsId/`,
      customerSupportPhoneNum: "1-800-403-6089",
      showCustomerSupportInfo: false,
      customerSupportHours: "8 am - 10 pm",
      designBuilderApiBaseUrl: `${customizableProductsBaseUrl}/specialorders/designs-api/`,
      designBuilderApiKey: "EDD4BE1A-D42A-4C45-9AB5-98DE244BFC5E",
      resurfacerUrl: `${customizableProductsBaseUrl}/decking-calculator`,
      supportedBrands: "Trex, Veranda, Fiberon, TimberTech",
      relatedProductIds: [],
      pageMode: "create"
    });
}

export const injectedConfiguratorSettings = (itemId) => ({...getConfiguratorSettings(itemId)});

export default getConfiguratorSettings();
