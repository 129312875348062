import {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react';

import { ExperienceContext } from '@thd-nucleus/experience-context';

import {
  checkForAnyErrors, getDefaultErrorSchema, hasError
} from '../helpers/errorHandlingUtil';
import {
  getCalculatorLocalStorage,
  removeStorageObject,
  saveStorageObject
} from '../helpers/localStorageUtil';
import { INSTALLED_CARPET_SEGMENT, MIN_AREA_NUMBER } from '../constants';
import { getCoverage, getDefaultArea, getQuantity } from '../helpers/helpers';
import { track } from '../analytics/analytics';

const getCleanValue = (value) => {
  if (value) {
    return value === '.' ? '0' : Number(value).toString();
  }
  return '';
};

const cleanNumberValues = ({ areas, calculatorType }) => {
  const cleanedAreas = areas.map((area) => {
    return {
      ...area,
      length: getCleanValue(area.length),
      width: getCleanValue(area.width),
      depth: calculatorType === 'Volume' ? getCleanValue(area.depth) : '',
      squareFootage: getCleanValue(area.squareFootage),
    };
  });
  return cleanedAreas;
};

const resolveDuplicateNames = (areas) => {
  if (areas && areas.length) {
    // eslint-disable-next-line no-undef
    const areasCopy = structuredClone(areas);

    // Removes existing trailing numbers from the areas array
    const removeTrailingNumber = (area) => {
      const split = area.name.trim().split(' ');

      if (+split[split.length - 1]) {
        return {
          ...area,
          name: split.slice(0, -1).join(' ')
        };
      }
      return {
        ...area,
        name: area.name.trim()
      };

    };
    const areasWithoutNumbers = areasCopy.map(removeTrailingNumber);

    // Creates an object where each key is a name of an area, and the value is all of the indexes of
    // the areas object that share that name
    const duplicateMap = {};
    for (let i = 0; i < areasWithoutNumbers.length; i += 1) {

      if (duplicateMap[areasWithoutNumbers[i].name.toLowerCase()]) {
        duplicateMap[areasWithoutNumbers[i].name.toLowerCase()].push(i);
      } else {
        duplicateMap[areasWithoutNumbers[i].name.toLowerCase()] = [i];
      }
    }

    // Going through each unique name in the duplicate map,
    // update each value in the stripped areas array to add trailing numbers back for all duplicate names
    // eslint-disable-next-line no-restricted-syntax
    for (const duplicateName in duplicateMap) {
      if (duplicateMap[duplicateName].length > 1) {
        for (let i = 1; i < duplicateMap[duplicateName].length; i += 1) {
          areasWithoutNumbers[duplicateMap[duplicateName][i]].name += ' ' + (i + 1);
        }
      }
    }

    return areasWithoutNumbers;
  }

  return areas;
};

export const useCalculator = ({
  allowAdditionalCoverage, calculatorType, info, l1Label, lineItemName, onCalculate, unitsPerCase
}) => {
  const { segment } = useContext(ExperienceContext);
  const [calculate, setCalculate] = useState({
    addAdditionalCoverage: true,
    quantity: 0,
    coverage: 0,
    areas: [
      getDefaultArea({ index: 0, calcByArea: false, calculatorType, lineItemName })
    ],
    areaErrors: [
      getDefaultErrorSchema()
    ],
    // TODO: implement this into the UI at a later point. Should probably only be used on carpets
    // Currently used to work with GCC Carpet Configurator (which does currently use stairs)
    stairs: '0',
    isPresentInLocalStorage: false,
    changesAwaitingLocalStorage: false
  });

  /*
    This is required due to refs persisting when component unmounts.
    Which is required for saving to localStorage when unmounting
  */
  const calculateRef = useRef(calculate);
  const areaBackupKVPRef = useRef({}); // KVP = Key Value Pair
  const storageKeyRef = useRef(null);

  // This function can take either a value or a function to set the new calculate state
  const setCalculateStateAndRef = (calcValOrFunc) => {
    if (typeof calcValOrFunc === 'function') {
      setCalculate((prevState) => {
        const newState = calcValOrFunc(prevState);
        calculateRef.current = newState;
        return newState;
      });
    } else {
      setCalculate(calcValOrFunc);
      calculateRef.current = calcValOrFunc;
    }
  };

  const addElement = ({ calcByArea }) => {
    const {
      areas,
      areaErrors
    } = calculate;

    // eslint-disable-next-line no-undef
    const newAreas = structuredClone(areas);
    newAreas.push(getDefaultArea({ index: areas.length + 1, calcByArea, calculatorType, lineItemName }));
    const resolvedNewAreas = resolveDuplicateNames(newAreas);

    // eslint-disable-next-line no-undef
    const newAreaErrors = structuredClone(areaErrors);
    newAreaErrors.push(getDefaultErrorSchema());

    setCalculateStateAndRef({
      ...calculate,
      areas: resolvedNewAreas,
      areaErrors: newAreaErrors
    });
    // Scroll to latest area
    setTimeout(() => {
      const sectionDivs = document.querySelectorAll('#calc-input-sections > div');
      if (sectionDivs.length > 0) { // This doesn't exist in FlooringCalculator
        const lastSectionOffsetTop = sectionDivs[sectionDivs.length - 1].offsetTop;
        const sections = document.getElementById('calc-input-sections');
        if (typeof sections.scroll === 'function') { // tests break, so added this check as a temp fix at the least
          sections.scroll({
            top: lastSectionOffsetTop,
            behavior: 'smooth'
          });
        }
      }
    }, 100);
  };

  const calculateTotal = ({ isClick, calculateOverride = null }) => {
    const calcStateToUse = calculateOverride || calculate;
    const {
      addAdditionalCoverage,
      areas
    } = calcStateToUse;

    const resolvedAreas = resolveDuplicateNames(areas);
    const cleanedAreas = cleanNumberValues({ areas: resolvedAreas, calculatorType });
    const strictErrors = checkForAnyErrors({
      calculatorType, areas: cleanedAreas
    });
    const isError = hasError(cleanedAreas, strictErrors);
    // If there's an error and it's a soft calculate (user didn't click), then we shouldn't set state
    if (isError && !isClick) {
      return;
    }
    const sizeValue = +unitsPerCase;
    let { coverage, quantity } = calcStateToUse;

    if (!isError) {
      coverage = getCoverage({
        areas: cleanedAreas,
        calculatorType,
        info,
        isInstalledCarpet: segment === INSTALLED_CARPET_SEGMENT
      });
      quantity = getQuantity({
        addAdditionalCoverage,
        allowAdditionalCoverage,
        coverage,
        info,
        sizeValue
      });

      if (isClick) {
        track();
      }
    }
    const ret = {
      ...calcStateToUse,
      quantity,
      coverage,
      areaErrors: strictErrors,
      areas: !isError ? cleanedAreas : resolvedAreas
    };
    if (onCalculate) {
      onCalculate({
        addAdditionalCoverage: ret.addAdditionalCoverage,
        quantity: ret.quantity,
        coverage: ret.coverage,
        error: isError
      });
    }
    setCalculateStateAndRef(ret);
  };

  const saveToLocalStorageFunc = () => {
    const { changesAwaitingLocalStorage } = calculateRef.current;

    if (changesAwaitingLocalStorage) {
      const { areas } = calculateRef.current;

      const cleanedAreas = cleanNumberValues({ areas, calculatorType });

      const strictErrors = checkForAnyErrors({
        calculatorType, areas: cleanedAreas
      });

      if (!hasError(cleanedAreas, strictErrors) && storageKeyRef.current) {
        const cleanedCalculate = {
          ...calculateRef.current,
          areaErrors: strictErrors,
          areas: cleanedAreas
        };
        saveStorageObject(storageKeyRef.current, cleanedCalculate);
        setCalculateStateAndRef({
          ...cleanedCalculate,
          isPresentInLocalStorage: true,
          changesAwaitingLocalStorage: false
        });
      }
    }
  };

  const removeInputElement = ({ index = null, shouldRenameAreas = true }) => {
    setCalculateStateAndRef((prevState) => {
      const { areas } = prevState;
      let ret;
      // Add deleted area(s) to areaBackupKVPRef
      areaBackupKVPRef.current = {
        ...areaBackupKVPRef.current,
        [`delete-${index === null ? 'all' : areas[index].key}`]: {
          areas: index !== null ? [areas[index]] : areas,
          areaErrors: index !== null ? [prevState.areaErrors[index]] : prevState.areaErrors,
        }
      };
      if ((index === 0 && areas.length === 1) || index === null) {
        // Gets highest calcByArea value to match customer preference
        const calcByArea = areas.map((area) => area.calcByArea).sort().reverse()[0];
        ret = {
          ...prevState,
          coverage: 0,
          quantity: 0,
          areaErrors: [getDefaultErrorSchema()],
          areas: [getDefaultArea({ index: 0, calcByArea, calculatorType, lineItemName })],
          isPresentInLocalStorage: false
        };
        calculateRef.current = ret;
        removeStorageObject(storageKeyRef.current);
      } else if (areas.length > MIN_AREA_NUMBER) {
        // These must be copied to avoid mutating the previous state, which mutates the areaBackupKVPRef
        // eslint-disable-next-line no-undef
        const prevAreas = structuredClone(prevState.areas);

        // eslint-disable-next-line no-undef
        const prevAreaErrors = structuredClone(prevState.areaErrors);

        prevAreas.splice(index, 1);
        prevAreaErrors.splice(index, 1);

        const resolvedPrevAreas = resolveDuplicateNames(prevAreas);

        ret = {
          ...prevState,
          changesAwaitingLocalStorage: true,
          areaErrors: prevAreaErrors,
          areas: resolvedPrevAreas,
          coverage: getCoverage({
            areas: prevAreas,
            calculatorType,
            info,
            isInstalledCarpet: segment === INSTALLED_CARPET_SEGMENT
          })
        };
        calculateRef.current = ret;
        saveToLocalStorageFunc();
      }
      return ret;
    });
  };

  const undoRemoveElement = (deleteKey) => {
    let tempBackup = areaBackupKVPRef.current;
    const { areas } = calculateRef.current;
    const { storageObj } = getCalculatorLocalStorage({
      l1Label
    });
    let revertedAreas;
    let revertedAreaErrors;
    if (!storageObj) { // This means there are no real areas, so we revert to the backup fully
      ({ areas: revertedAreas, areaErrors: revertedAreaErrors } = areaBackupKVPRef.current[deleteKey]);
    } else { // This means there are real areas, so we need to merge the backup with the real areas in the correct order
      revertedAreas = [
        ...areas,
        ...tempBackup[deleteKey].areas
      ].sort((area1, area2) => area1.key - area2.key);
      const deleteIndex = revertedAreas.findIndex((area) => {
        return deleteKey === 'all'
          ? area.key === tempBackup[deleteKey].areas[0].key
          : area.key === deleteKey;
      });
      revertedAreaErrors = calculateRef.current.areaErrors
        .splice(deleteIndex, 0, tempBackup[deleteKey].areaErrors);
    }
    calculateRef.current = {
      ...calculateRef.current,
      areas: revertedAreas,
      areaErrors: revertedAreaErrors,
      changesAwaitingLocalStorage: true
    };
    saveToLocalStorageFunc();
    calculateTotal({ isClick: false, calculateOverride: calculateRef.current });

    delete tempBackup[deleteKey];
    areaBackupKVPRef.current = tempBackup;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', saveToLocalStorageFunc);
    return () => {
      saveToLocalStorageFunc();
      window.removeEventListener('beforeunload', saveToLocalStorageFunc);
    };
  }, []);

  useEffect(() => {
    if (l1Label) {
      const { defaultAdditionalCoverage } = info?.pipCalculator || {};
      const { storageKey, storageObj } = getCalculatorLocalStorage({
        l1Label
      });
      storageKeyRef.current = storageKey;
      if (storageObj) {
        const { areas: lsAreas, ...otherLSProps } = storageObj;
        const newCalculate = {
          ...calculate,
          addAdditionalCoverage: defaultAdditionalCoverage,
          ...otherLSProps,
          areas: lsAreas.map((area, index) => {
            return {
              // This gets default area values in case the localStorage object is missing any
              ...getDefaultArea({ index, calcByArea: false, calculatorType, lineItemName }),
              ...area,
              key: `${index}`
            };
          }),
          areaErrors: new Array(lsAreas.length).fill('').map(() => getDefaultErrorSchema()),
          isPresentInLocalStorage: true
        };
        calculateTotal({ isClick: false, calculateOverride: newCalculate });
      } else {
        setCalculateStateAndRef((prevState) => {
          return {
            ...prevState,
            addAdditionalCoverage: defaultAdditionalCoverage
          };
        });
      }
    }
  }, [l1Label]);

  const resolveNames = useCallback(() => {
    setCalculateStateAndRef({
      ...calculate,
      areas: resolveDuplicateNames(calculate.areas)
    });
  }, [setCalculateStateAndRef]);

  useEffect(() => {
    // TODO: add analytics call for addAdditionalCoverage when we get specs from Analytics on this
    calculateTotal({ isClick: false });
  }, [calculate.addAdditionalCoverage, info?.pipCalculator?.publisher, unitsPerCase]);

  useEffect(() => {
    saveToLocalStorageFunc();
  }, [calculate.addAdditionalCoverage]);

  return [calculate, {
    addElement,
    calculateTotal,
    saveToLocalStorageFunc,
    removeInputElement,
    setCalculateStateAndRef,
    undoRemoveElement,
    resolveNames
  }];
};