/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from '@thd-olt-component-react/router';
import { extend, QueryContext } from '@thd-nucleus/data-sources';
import { useBreakpoint } from '@one-thd/sui-atomic-components';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import cs from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { withContainer, PIPPageContainer, parseGrid } from '@thd-olt-component-react/fusion-utils';
import { declareContexts } from '@thd-olt-component-react/hydrator';
import { SlotWrapper } from '../components/util/SlotWrapper';
import staticConfig from '../../fusion-config.json';
import staticSlotMap from '../../fusion-slot-map.json';
import staticSectionSlots from '../../fusion-section-slots.json';
import { Slot } from '../components/Slot';
import { useEventListener } from './Page.events';

const config = {
  ...staticConfig,
  slots: staticSlotMap
};

const hasStickyAtOrAboveBreakpoint = (bk, row) => {
  const breakpoints = ['sm', 'md', 'lg', 'xl', '2xl'];
  const index = breakpoints.indexOf(bk);
  if (index === -1) return false;
  breakpoints.splice(index + 1, breakpoints.length);
  return breakpoints.some((key) => {
    return row[key]?.sticky;
  });
};

const Page = () => {
  const productId = useParams()?.itemId;
  const [itemId, setItemId] = useState(productId);
  const qctx = useContext(QueryContext);
  const [mounted, setMounted] = useState(false);
  useEventListener({ itemId, setItemId });
  declareContexts([QueryContext, ExperienceContext], { freeze: true });

  useEffect(() => {
    if (productId !== itemId) {
      setItemId(productId);
    }
  }, [productId]);

  const props = {
    itemId,
    // JSON.parse(JSON.stringify) is needed because the config
    // is mutated from $itemId to actual values.
    // without this the values will persist across requests/
    // a better solution would be to freeze this object and
    // then not mutate the config but return props as part of a
    // new object
    // CON-2720
    config: JSON.parse(JSON.stringify(config))
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  const { current: currentBreakpoint } = useBreakpoint('md', { current: true });

  const getLetterFromIndex = (index) => {
    const letter = String.fromCharCode(65 + index);
    return letter;
  };

  const getSlotsFromIndex = (index) => {
    const letter = getLetterFromIndex(index);
    return staticSectionSlots[letter];
  };

  return (
    <div className="sui-w-full 2xl:sui-container sui-mx-auto sui-my-0">

      <div className="sui-grid sui-grid-cols-12 sui-gap-x-5">
        {config.grid.map((row, index) => {
          const slotsArray = getSlotsFromIndex(index);
          if (!slotsArray) return null;
          const letter = getLetterFromIndex(index);
          const cnames = parseGrid(row);
          return (
            <ErrorBoundary name={`${config?.name}-section-${letter?.toUpperCase()}`}>
              <div
                key={index}
                data-fusion-section={letter?.toUpperCase()}
                className={cs(
                `sui-col-span-${row.default?.cols || '12'}`,
                'sui-flex',
                `sui-flex-${row.default?.direction || 'col'}`,
                'fusion-row', {
                  [`sm:sui-col-span-${row.sm?.cols}`]: !!row.sm?.cols,
                  [`md:sui-col-span-${row.md?.cols}`]: !!row.md?.cols,
                  [`lg:sui-col-span-${row.lg?.cols}`]: !!row.lg?.cols,
                  [`xl:sui-col-span-${row.xl?.cols}`]: !!row.xl?.cols,
                  [`sm:sui-flex-${row.sm?.direction}`]: !!row.sm?.direction,
                  [`md:sui-flex-${row.md?.direction}`]: !!row.md?.direction,
                  [`lg:sui-flex-${row.lg?.direction}`]: !!row.lg?.direction,
                  [`xl:sui-flex-${row.xl?.direction}`]: !!row.xl?.direction,
                },
                cnames,
                )}
              >
                <SlotWrapper sticky={mounted && hasStickyAtOrAboveBreakpoint(currentBreakpoint, row)}>
                  {slotsArray.map((slot) => (
                    <Slot
                      key={`slot${slot.section}${slot.slot}`}
                      name={`slot${slot.section}${slot.slot}`}
                      qctx={qctx}
                      {...props}
                    />
                  ))}
                </SlotWrapper>
              </div>
            </ErrorBoundary>
          );
        })}
      </div>
    </div>
  );
};

Page.propTypes = {
};

Page.displayName = 'Page';

Page.defaultProps = {
  name: '',
};

Page.dataModel = extend({},
  Slot,
);

const Wrapped = withContainer(Page, PIPPageContainer);
export { Wrapped as Page };
